.chat__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  background-color: #1c1c1c;
  border-top: 1px solid #262c26;
  border-left: 1px solid #212621;
  transition: 1s;
  gap: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.MuiSvgIcon-root {
  color: #8899a6;
}

.chat__footer > form {
  flex: 1;
  display: flex;
}
.chat__footer > form > input {
  flex: 1;
  border-radius: 15px;
  padding: 10px;
  border: none;
  outline: none !important;
  color: white;
  background-color: #262c26;
  border-top-style: hidden !important;
  border-right-style: hidden !important;
  border-left-style: hidden !important;
  border-bottom-style: hidden !important;
}
.chat__footer > form > input:focus {
  outline: none;
}
.chat__footer > form > button {
  display: none;
}
.chat__footer > .MuiSvgIcon-root {
  padding: 10px;
  color: white;
}

.MuiButtonBase-root {
  background-color: #262c26 !important;
}

.MuiSvgIcon-root {
  color: #b4b4b4 !important;
}

.chat__footer_emoji {
  display: none;
}
.emoji-mart {
  position: absolute !important;
  bottom: 60px;
  width: 70vw !important;
  height: 50vh;
  background-color: #262c26;
}

.emoji-mart-search-10 {
  background-color: #262c26;
}

.emoji-mart-category-label {
  background-color: #262c26;
}

.emoji-mart-bar {
  border: 0;
}
.emoji-mart-anchors {
  padding: 0;
  border: 0;
}
.emoji-mart-anchor-icon {
  color: #a3a3a3;
}
.emoji-mart-anchor-selected {
  color: red !important;
}
.emoji-mart-anchor {
  cursor: pointer;
}
.emoji-mart-anchor-bar {
  background-color: #28d146 !important;
}
.emoji-mart-anchor-icon {
  color: #8b8b8b !important;
}
.emoji-mart-search {
  margin: 5px 8px 15px 8px;
}
.emoji-mart-search-icon {
  display: none;
}
.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 0px solid #d9d9d9;
  outline: 0;
  background-color: #212621;
  color: #4a4a4a;
  color: white;
}
.emoji-mart-search input::placeholder {
  color: #989898;
}
.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  color: #b4b4b4;
  background-color: #212621;
}
.emoji-mart-scroll {
  height: 200px;
}
.emoji-mart-bar:last-child {
  display: none;
}

.chat__attachFile {
  position: absolute;
  bottom: 70px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  overflow: hidden;
}
.chat__attachFile > .MuiFab-root {
  margin: 7px 0 7px 0;
}
.chat__icon {
  height: 24px;
}
.chat__icon > label > .MuiSvgIcon-root {
  cursor: pointer;
  fill: #28d146;
  color: black;
}
.chat__icon > input {
  display: none;
}
@media screen and (max-width: 600px) {
  .chat__footer {
    position: sticky;
    bottom: 0;
    z-index: 100;
    background-color: #1c1c1c !important;
  }
  .chat__footer > .MuiSvgIcon-root {
    color: gray !important;
  }
  .emoji-mart {
    position: absolute !important;
    bottom: 60px;
    width: 100vw !important;
    height: 40vh;
  }
  .emoji-mart-scroll {
    height: 140px;
  }
  .emoji-mart-category-label {
    top: -3px;
  }
}
