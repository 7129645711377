h3,
p {
  margin: 0;
}
.chat {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.Toastify__toast {
  font-size: 14px;
  min-height: 30px !important;
  max-height: 80px !important;
  top: 60px;
}

/* Start: Chat Header */
.chat__header {
  padding: 8px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #262c26;
}
/* End: Chat Header */

/* Start: Chat Body */
.chat__body {
  flex: 1;
  height: 100dvh;
  /* background-color: aliceblue; */
  /* background-image: url("../images/background.jpg"); */
  /* background-repeat: repeat; */
  /* background-position: center; */
  padding: 40px 90px 0px 90px;
  overflow-y: scroll !important;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  overflow-x: hidden;
}

.chat__body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.chat__body_loading {
  display: table;
  text-align: center;
  width: 100%;
  height: 100%;
}
.chat__body_loading > div {
  display: table-cell;
  vertical-align: middle;
  color: #4a4a4a;
}

/* End: Chat Body */

@media screen and (max-width: 600px) {
  .chat {
    flex: 1;
    height: 100dvh;
    width: 100vw;
  }
  .Toastify__toast {
    font-size: 12px;
    min-height: 30px !important;
    max-height: 50px !important;
    max-width: 80vw;
    /* margin-bottom: 10px !important; */
    margin-top: 20px !important;
    /* margin-left: 80px; */
    margin: 0 auto;
  }

  /* Start: Chat Header */
  .chat__header {
    padding: 6px;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #1c1c1c;
  }
  /* End: Chat Header */

  /* Start: Chat Body */
  .chat__body {
    padding: 40px 12px 0px 12px !important;
  }
  /* End: Chat Body */
}

@media screen and (max-width: 800px) {
  .chat__body {
    padding: 40px 30px 0px 30px;
  }
}
