.chat__message_reminder {
  background-color: #fdf4c4;
  font-size: 16px;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  width: fit-content;
  margin-bottom: 15px !important;
  margin: auto;
}
.chat__message_reminder > .MuiSvgIcon-root {
  color: #4a4a4a;
  font-size: small;
}
.chat__createdBy {
  margin-bottom: 70px !important;
  background-color: #e1f3fb !important;
}

.chat__message {
  display: flex;
  align-items: center;
  position: relative;
  /* justify-content: space-between; */
  color: #fff;
  font-size: 14px;
  padding: 6px 7px 8px 9px;
  margin-bottom: 25px;
  border-radius: 6px;
  background-color: #212621;
  white-space: normal;
  width: fit-content;
  height: fit-content;
  word-break: break-all;
  padding-right: 60px;
}

.chat__container {
  display: flex;
  align-items: center;
  margin-right: auto;
  /* max-width: 700px; */
  /* position: relative; */
  /* justify-content: space-between; */
}

.chat__profile {
  /* justify-content: space-between; */
  position: relative;
  top: -10px;

  margin: 10px;
}

.chat__receiver {
  display: flex;
  margin-left: auto;
  position: relative;

  background-color: #28d146;
  order: 0;
}

.chat__message .chat__receiver {
  padding-right: -30px;
}

.chat__profile_sender {
  position: relative;
  order: 1;
}

.chat__message_media_image {
  padding: 4px !important;
  border-radius: 6px !important;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
}
.chat__message_media_video {
  padding: 4px !important;
  border-radius: 6px !important;
}
.chat__message_media_video_noCaption {
  padding: 4px !important;
  border-radius: 6px !important;
}

.chat__name {
  top: -15px;
  font-size: xx-small;
  font-weight: 800;
  white-space: nowrap;
  position: absolute;
}
.chat__name_sender {
  right: 10px;
}

.chat__body_image_container {
  display: flex;
  flex-direction: column;
  /* max-width: 700px; */
  /* cursor: pointer; */
}
.chat__body_image {
  /* max-height: 200px */
  max-width: 100%;
  border-radius: 8px;
}

.chat__body_video_container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}
.player-wrapper {
  position: relative;
  width: 30vw;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  margin-bottom: 10px;
}

.player-twitter {
  position: relative;
  /* width: 30vw; */
  padding: 0 !important;
  justify-content: center;
  align-items: center;

  left: 2.5px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 2.5px;
  /* left: 2px; */
}

a {
  margin-bottom: 10px;
  margin-left: 10px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* line-number */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  max-width: 25vw;
}

.chat_message_container {
  display: flex;
}

.chat__message_box {
  display: flex;
  max-width: 600px;
  margin-right: 8px;
  /* position: relative; */
  /* align-items: center;
  width: fit-content; */
  /* justify-content: space-between; */
  /* margin: 5px; */
  /* margin-left: 20px; */
}

.chat__receiver .chat__message_box {
  margin-right: -30px;
}

.chat__message_box_video {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* display: none; */
}

.chat__message_box_text_video {
  /* display: none; */
  /* margin-left: 10px; */
}

.chat__body_image_container img {
  /* margin-bottom: 15px; */
  position: relative;
  /* left: 2.5px; */
  /* margin-left: 3.5px; */
}

.chat__message_box_text {
  padding-left: 5px;
  max-width: 40vw;
}

.chat__timestamp_container {
  display: relative;
  margin-left: 45px;
}
.chat__timestamp_container_video {
  display: relative;
  margin-left: 0px;
}
.chat__timestamp_container_sender {
  display: relative;
  margin-left: 65px;
}
.chat__timestamp_container_sender_video {
  display: relative;
  margin-left: 0px !important;
}
.chat__timestamp {
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  bottom: 3px;
  right: 7px;
  color: rgb(155, 153, 153);
}
.chat__timestamp_media_photo {
  /* color: #ffffff !important; */
  font-weight: 600;
  /* font-size: 12px; */
  right: 4px;
  border-radius: 0 0 8px 8px;
  left: 4px;
  height: 25px;
  background: linear-gradient(transparent 0%, #302c2c81 100%);
}

.chat__timestamp_media_video {
  color: rgb(155, 153, 153);
  font-weight: 600;
  /* font-size: 12px; */
  right: 0;
  bottom: 0;
  /* height: 30px; */
  background: linear-gradient(transparent 0%, #302c2c81 100%);
  width: 100%;
}
.chat__timestamp_media_photo > span {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
}
.chat__timestamp_media_video > span {
  float: right;
  margin-top: 10px;
  margin-right: 5px;
}
.chat__timestamp_media_displayNone {
  display: none;
}
.chat__timestamp_container > .chat__timestamp > span > .MuiSvgIcon-root {
  font-size: 13px !important;
  margin-left: 2px;
}

.scroll-down {
  color: white;
  position: absolute;
  bottom: 80px;
  right: 30px;
  z-index: 300;
}

.chat__receiver .chat__timestamp {
  color: white;
}

.chat__receiver .chat__timestamp .MuiSvgIcon-root {
  fill: white;
}

.chat__receiver svg {
  fill: white;
}

@media screen and (max-width: 820px) {
  .chat__container {
    /* max-width: 55vw; */
  }
  .chat__message_media_image {
    max-width: 55vw;
  }

  .chat__body_video_container {
    max-width: 55vw;
  }

  .chat__message_box {
    max-width: calc(55vw - 10px);
  }
}

@media screen and (max-width: 600px) {
  .chat__message_media_image {
    max-width: 65vw;
  }

  .chat__receiver .chat__message_box {
    margin-right: 10px;
  }

  .chat__body_video_container {
    max-width: 70vw;
  }

  .chat__message_box {
    max-width: calc(70vw - 0px);
  }

  .chat__message_reminder {
    font-size: 12px;
  }
  .chat__createdBy {
    margin-bottom: 50px !important;
  }

  .chat__message {
    font-size: 12px;
    padding: 4px 5px 6px 7px;
    /* max-width: 75vw; */
  }
  .chat__message_media {
    padding: 4px !important;
    border-radius: 4px !important;
  }

  .chat__message_box_text {
    padding-left: 5px;
    max-width: 700px;
    word-break: break-all;
  }

  .chat__message_box_text_video {
    /* z-index: 100; */
    /* margin-left: 0px !important; */
    margin-left: -75px;
    max-width: 60vw;

    word-break: break-all;
  }

  .chat__body_image {
    /* max-height: 150px; */
    max-width: 100%;
    border-radius: 8px;
  }

  .chat__body_video {
    /* max-height: 150px; */
    max-width: 100%;
    border-radius: 8px;
  }

  .player-wrapper {
    width: 70vw;
  }

  .player-twitter {
    width: 70vw !important;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0 !important;
  }

  a {
    max-width: 60vw !important;
  }
  /* 
  .chat__message_media_video_noCaption {
    padding: 4px !important;
    border-radius: 6px !important;
  } */

  .react-player-caption {
    max-width: 50vw;
    height: auto;
  }

  .chat__message_media_video {
    padding: 4px !important;
    border-radius: 6px !important;
    /* max-height: 150px !important;
    max-width: 100% !important; */
    /* max-width: 70vw; */
  }

  .chat__message_box_text {
    /* margin-left: -80px; */
  }

  .chat__timestamp_container {
    margin-left: 45px;
    margin-right: 5px;
  }
  .chat__timestamp_container_sender {
    margin-left: 55px;
  }
}
