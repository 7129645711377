.app {
  display: grid;
  place-items: center;
  background-color: #1c1c1c;
  height: 100dvh;
}

.app__body {
  display: flex;
  background-color: #1c1c1c;
  height: 100dvh;
  width: 100vw;
  /* margin-top: -50px; */
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7);
}

.app__loading {
  display: table;
  text-align: center;
  height: 100%;
  width: 350px;
}

.app__loading > div {
  display: table-cell;
  vertical-align: middle;
}

.app__loading > div > .app__loading_circular {
  margin-top: 100px !important;
}

.app__loading > div > .app__loading_linear .MuiLinearProgress-root {
  margin-top: 100px !important;
}

.MuiCircularProgress-colorPrimary {
  color: white !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #28d146 !important;
}

input,
button {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.enter_name_container {
  display: flex;
  width: 100vw;
  height: 100dvh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
}

.enter_name_container input {
  outline: 0;
  padding: 10px;
  background-color: transparent;
  border-width: 2px;
  color: white;
  font-weight: 700;
  border-color: white;
}

.enter_name_container button {
  color: black;
}

@-webkit-keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
      50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
@-webkit-keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.enter_name_container button {
  margin-top: 20px;
  padding: 10px;
  width: 200px;
  cursor: pointer;
  border-color: transparent;
  font-weight: bold;
  background-color: #28d146;
}

.Toastify__toast--info {
  background-color: #28d146 !important;
}

@media screen and (max-width: 600px) {
  .app__body {
    display: inline;
  }
  .app__loading {
    width: 200px;
  }
}
