.search {
  display: flex;
  align-items: center;
  background-color: #1c1c1c;
  /* color: white; */
  height: 49px;
  padding: 2px 10px 2px 10px;
  margin-bottom: 2px;
}

/* .search__bgColorChange {
  background-color: #212621 !important;
  -webkit-box-shadow: 0px 2px 2px #262c26;
  -moz-box-shadow: 0px 2px 2px #262c26;
  box-shadow: 0px 2px 2px #262c26;
} */
.search__container {
  display: flex;
  align-items: center;
  background-color: #212621;
  width: 100%;
  border-radius: 20px;
  transition: 4.5s;
  height: 40px;
  outline: none;
}
.search__container > input {
  color: white;
  outline: none !important;
  background-color: #212621;
  margin-left: 5px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  border-top-style: hidden !important;
  border-right-style: hidden !important;
  border-left-style: hidden !important;
  border-bottom-style: hidden !important;
}
.search__container > input:focus {
  outline: none;
  background-color: #212621;
  color: white;
}
input:focus + div {
  display: none;
}

.search__container > .MuiSvgIcon-root > input::placeholder {
  color: #8899a6;
}
.search__container > span > .MuiSvgIcon-root {
  padding: 10px;
}
.search__container > span {
  transition: 0.3s;
  color: #8899a6;
}
.arroww {
  color: #33b7f6 !important;

  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
