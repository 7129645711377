.chat__header {
  padding: 8px;
  display: flex;
  align-items: center;
  background-color: #1c1c1c;
  border-bottom: 1px solid #262c26;
  transition: 1s;
}
.chat__headerRight {
  display: flex;
  justify-content: flex-end;
  min-width: 100px;
}
.chat__header > .MuiAvatar-root {
  margin-left: 8px;
}
.chat__headerInfo {
  flex: 1;
  padding-left: 20px;
}
.chat__headerInfo > h3 {
  font-weight: 500;
}
.chat__headerInfo > p {
  color: #8899a6;
}
.chat__attachFile_drawerHeader {
  background-color: #212621;
}
.chat__attachFile_drawerHeader_container {
  display: flex;
  align-items: center;
}
.chat__attachFile_drawerHeader_container > p {
  color: white;
  font-size: 20px;
  font-weight: 500;
}
.chat__attachFile_drawerHeader_container > .MuiIconButton-root {
  color: white !important;
  margin: 0 10px 0 10px;
}
.chat__attachFile_drawerContent {
  background-color: #e6e6e6;
  height: 100%;
}
.chat__attachFile_drawerContent_photo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat__attachFile_drawerContent_photo > img {
  height: 244px;
}

.pin_caption {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.pin_caption input {
  width: 100%;
  outline: 0;
  padding: 10px;
  background-color: transparent;
  border-width: 0 0 2px;
  color: white;
  font-weight: 700;
  border-color: white;
}

.pin_header {
  height: 80px;
  z-index: 1 !important;
}

.pin_name {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pin_divider {
  width: 3px;
  height: 100%;
  margin-right: 10px;
  background-color: white !important;
}

.pin_container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: white;
  /* width: 100vw; */
  /* padding-right: 30px; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.pin_container p {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.pin_photo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 5px;
}

.menu_header {
  color: white;
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.menu_header_content {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 600px) {
  .chat__header {
    padding: 6px;
    position: sticky;
    top: 0;
    z-index: 100;
    overflow-x: hidden;
    background-color: #1c1c1c;
  }
  .chat__headerInfo > p {
    color: #8899a6;
  }
  .chat__headerRight {
    min-width: 80px;
  }
  .chat__headerInfo {
    padding-left: 6px;
  }
  .chat__headerInfo > h3 {
    color: white;
  }
  .chat__headerInfo > p {
    font-size: 12px;
    color: white;
  }
  .MuiIconButton-root {
    padding: 5px !important;
  }
  .MuiIconButton-root {
    color: white !important;
  }
  .pin_container p {
    font-size: 11px;
  }
}
