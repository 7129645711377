.login {
  width: 100vw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  gap: 10px;
}

.login input {
  outline: 0;
  padding: 10px;
  background-color: transparent;
  border-width: 0 0 2px;
  color: white;
  font-weight: 700;
  width: 250px;
  border-color: white;
}

.login button {
  margin-top: 20px;
  padding: 10px;
  width: 250px;
  cursor: pointer;
  border-color: transparent;
  font-weight: bold;
  background-color: #28d146;
}

.login p {
  color: red;
  font-size: 13px;
}
