.MuiDialogTitle-root {
  background-color: #1c1c1c !important;
}

.MuiDialogContent-root {
  /* margin: 0 auto; */
  background-color: #1c1c1c !important;
  /* padding: 0 24px !important; */
  max-width: 100vw !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DialogCustom__photo {
  /* max-height: 500px; */

  max-width: 80vw;
  max-height: 80vh;
}

@media screen and (max-width: 600px) {
  #alert-dialog-title-dialogCustom {
    background-color: #1c1c1c !important;
  }
}
